class ZIDXAccountRegister implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountRegisterContainer";
    }
    getMatchURL(){
        return "/register";
    }
    render(){
        // console.log("register");
    }
}